var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-green" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("assignment")])],
                  1
                ),
                _c("h4", { staticClass: "title" }, [_vm._v("Simple Table")]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c("md-table", {
                  attrs: { "table-header-color": "green" },
                  scopedSlots: _vm._u([
                    {
                      key: "md-table-row",
                      fn: function ({ item }) {
                        return _c(
                          "md-table-row",
                          {},
                          [
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "ID" } },
                              [_vm._v(_vm._s(item.id))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Name" } },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Country" } },
                              [_vm._v(_vm._s(item.country))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "City" } },
                              [_vm._v(_vm._s(item.city))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Salary" } },
                              [_vm._v(_vm._s(item.salary))]
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                  model: {
                    value: _vm.tableData,
                    callback: function ($$v) {
                      _vm.tableData = $$v
                    },
                    expression: "tableData",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-card",
          { staticClass: "md-card-plain" },
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-green" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("assignment")])],
                  1
                ),
                _c("h4", { staticClass: "title mt-0" }, [
                  _vm._v("Table on Plain Background"),
                ]),
                _c("p", { staticClass: "category" }, [
                  _vm._v("Here is a subtitle for this table"),
                ]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c("md-table", {
                  scopedSlots: _vm._u([
                    {
                      key: "md-table-row",
                      fn: function ({ item }) {
                        return _c(
                          "md-table-row",
                          {},
                          [
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "ID" } },
                              [_vm._v(_vm._s(item.id))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Name" } },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Country" } },
                              [_vm._v(_vm._s(item.country))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "City" } },
                              [_vm._v(_vm._s(item.city))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Salary" } },
                              [_vm._v(_vm._s(item.salary))]
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                  model: {
                    value: _vm.tableDataPlain,
                    callback: function ($$v) {
                      _vm.tableDataPlain = $$v
                    },
                    expression: "tableDataPlain",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c(
          "md-card",
          [
            _c(
              "md-card-header",
              { staticClass: "md-card-header-icon md-card-header-green" },
              [
                _c(
                  "div",
                  { staticClass: "card-icon" },
                  [_c("md-icon", [_vm._v("assignment")])],
                  1
                ),
                _c("h4", { staticClass: "title" }, [
                  _vm._v("Regular Table with Colors"),
                ]),
              ]
            ),
            _c(
              "md-card-content",
              [
                _c("md-table", {
                  staticClass: "table-full-width",
                  scopedSlots: _vm._u([
                    {
                      key: "md-table-row",
                      fn: function ({ item }) {
                        return _c(
                          "md-table-row",
                          { class: _vm.getClass(item) },
                          [
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "ID" } },
                              [_vm._v(_vm._s(item.id))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Name" } },
                              [_vm._v(_vm._s(item.name))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Country" } },
                              [_vm._v(_vm._s(item.country))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "City" } },
                              [_vm._v(_vm._s(item.city))]
                            ),
                            _c(
                              "md-table-cell",
                              { attrs: { "md-label": "Salary" } },
                              [_vm._v(_vm._s(item.salary))]
                            ),
                          ],
                          1
                        )
                      },
                    },
                  ]),
                  model: {
                    value: _vm.tableDataColor,
                    callback: function ($$v) {
                      _vm.tableDataColor = $$v
                    },
                    expression: "tableDataColor",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }